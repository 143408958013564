/* Main content container with even spacing */
.template-container {
  width: 210mm;
  /* Width to fit A4 margins */
  height: 297mm;
  /* Max height for A4 */
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.template-container-horizontal {
  width: 297mm;
  /* Width to fit A4 margins in landscape */
  height: 210mm;
  /* Max height for A4 in landscape */  
}

.dynamic-area {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.template-logo-placeholder {
  margin-top: 100px;
}

.product-image {
  -webkit-filter: drop-shadow(10px 7px 15px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(10px 7px 15px rgba(0, 0, 0, 0.2));
}

.product-table-section {
  width: 700px; 
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card-section {  
  font-size: 12px;
}

.contact-info-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: flex-end;
  width: 100%;
}

.watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  opacity: 0.3;
  font-size: 20rem; /* Adjust the font size as needed */
  color: #000; /* Adjust the color as needed */
  z-index: 1000; /* Ensure it is on top of other elements */
  pointer-events: none; /* Make sure it doesn't interfere with other elements */
}